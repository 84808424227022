<template>
  <rpm-layout>
    <div class="flex justify-between items-center bg-white rounded-lg p-2 mb-2 space-x-1">
      <div class="relative w-1/4">
        <RpmSearch v-model="search"></RpmSearch>
      </div>
      <div>
        <el-select v-model="client" value-key="id" clearable placeholder="Client" filterable>
          <el-option v-for="client in clients" :value="client" :label="client.name" :key="client.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="health_coach" value-key="id" clearable placeholder="Health Coach" filterable>
          <el-option v-for="health_coach in healthCoaches" :value="health_coach" :label="`${health_coach.first_name} ${health_coach.last_name}`" :key="health_coach.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="pcp" value-key="id" clearable placeholder="PCP" filterable>
          <el-option v-for="pcp in pcps" :value="pcp" :label="`${pcp.first_name} ${pcp.last_name}`" :key="pcp.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="status" clearable placeholder="Status">
          <el-option v-for="status in statuses" :value="status.status" :key="status.status">
            <el-tooltip :content="status.description" placement="top">
              <span>{{status.status}}</span>
            </el-tooltip>
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button type="default" @click="refresh">Refresh</el-button>
      </div>
    </div>
    <div class="relative overflow-x-auto sm:rounded-lg">
      <v-data-table :loading="loading" :headers="dtheaders" :items="dtpatients" @click:row="goToPatient($event.id)" :search="search" class="v-data-table cursor-pointer">
        <template v-slot:loading><v-progress-circular indeterminate></v-progress-circular></template>
        <template v-slot:[`item.name`]="{ item }">
          <button @click.stop="goToPatient(item.id, true)"><v-icon small>mdi-open-in-new</v-icon></button>
          {{ item.name }}
        </template>
        <template v-slot:[`item.dob`]="{ item }">
          {{ item.dob | dob }}
        </template>
        <template v-slot:[`item.cc_start_date`]="{ item }">
          {{ item.cache.ccm_date | date }}
        </template>
      </v-data-table>
    </div>
  </rpm-layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RpmLayout from '../../layouts/RpmLayout.vue';
import DataTable from 'datatables.net-dt';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-responsive-dt';
import dtOptions from "@/helpers/defaultDatatablesOptions";
import RpmSearch from "@/components/RpmSearch.vue";
import PatientMixin from "@/mixins/Patient";
import Template from "@/mixins/Template";
import statuses from "@/helpers/patientStatuses";

export default {
  mixins: [PatientMixin],
  components: {Template, RpmSearch, RpmLayout },
  data() {
    return {
      search: "",
      loading: true,
      client: null,
      pcp: null,
      health_coach: null,
      status: 'active',
      statuses: statuses,
    };
  },
  created: async function () {
    Promise.all([
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser').then(this.resetFilters) : null,
      !this.$store.state.patients.patients.length ? this.$store.dispatch('getPatients') : null,
      !this.$store.state.patients.providers.length ? this.$store.dispatch('getProviders') : null,
    ]).finally(() => {
      this.loading = false;
      this.resetFilters();
    });
  },
  computed: {
    ...mapState(["patients"]),
    authUser() {
      return this.$store.state.patients.authUser
    },
    healthCoaches() {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_health_coach') && !p.disabled);
    },
    pcps() {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_provider') && !p.disabled);
    },
    clients() {
      return this.$store.state.patients.authUser.available_clients;
    },
    dtheaders() {
      return [
        {text: 'Patient Name', value: 'name'},
        {text: 'Client', value: 'client'},
        {text: 'PCP', value: 'pcp'},
        {text: 'Health Coach', value: 'health_coach'},
        {text: 'Review Time This Month', value: 'review_time_this_month'},
        {text: 'DOB', value: 'dob'},
        {text: 'Gender', value: 'gender'},
        {text: 'ID', value: 'id'},
        {text: 'CCM Start Date', value: 'ccm_start_date'},
        {text: 'Health Condition', value: 'health_condition'},
      ];
    },
    dtpatients() {
      return this.filteredCCMPatients.map((patient) => ({
        ...patient,
        name: patient.first_name + " " + patient.last_name,
        client: this.$store.getters.clientName(patient.primary_client_id),
        pcp: patient.cache.careTeam ? this.getProviderName(patient.cache.careTeam.pcp) : null,
        health_coach: patient.cache.careTeam ? this.getProviderName(patient.cache.careTeam.care_manager) : null,
        review_time_this_month: patient.cache.task_duration_ccm_sum_monthly || '--',
        gender: patient.sex || '--',
        id: patient.id,
        health_condition: patient.cache.ccm_health_condition || '--',
      }));
    },
    ccmPatients: function () {
      return this.patients.patients.filter(p => p.cache && p.cache.ccm_date);
    },
    filteredCCMPatients() {
      let patients = this.ccmPatients;
      if (this.client) {
        patients = patients.filter((p) => p.primary_client_id === this.client.id);
      }
      if (this.health_coach) {
        patients = patients.filter((p) => p.cache.careTeam && p.cache.careTeam.care_manager === this.health_coach.id);
      }
      if (this.pcp) {
        patients = patients.filter((p) => p.cache.careTeam && p.cache.careTeam.pcp === this.pcp.id);
      }
      if (this.status) {
        patients = patients.filter((p) => p.status === this.status);
      }
      return patients;
    },
  },
  methods: {
    ...mapActions(["getPatients"]),
    resetFilters() {
      this.status = 'active';
      if (this.authUser.behaviors.includes('is_provider') && this.pcps.some(pcp => pcp.id === this.authUser.id)) {
        this.pcp = this.authUser;
      } else if (this.healthCoaches.some(hc => hc.id === this.authUser.id)) {
        this.health_coach = this.authUser;
      }
    },
    refresh() {
      this.loading = true;
      this.$store.dispatch('getPatients').finally(() => this.loading = false);
    },
    getProvider(id) {
      return this.patients.providers.find((p) => p.id === id);
    },
    getProviderName(id) {
      const p = this.getProvider(id);
      return p ? p.first_name + " " + p.last_name : "None";
    },
  },
};
</script>
